<template>
  <Layout>
    <div class="app-coupon-edit">
      <a-page-header title="优惠券" />
      <a-form-model :model="form" ref="form">
        <div class="adv-form">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item
                required
                label="券名称"
                prop="name"
                :rules="[
                  {
                    required: true,
                    message: '券名称必填'
                  }
                ]"
              >
                <a-input
                  v-model="form.name"
                  :max-length="10"
                  placeholder="请输入优惠券名称"
                />
              </a-form-model-item>

              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-model-item
                    required
                    label="领劵开始时间"
                    prop="start_time"
                    :rules="{
                      required: true,
                      message: '领劵开始时间必填'
                    }"
                  >
                    <a-date-picker
                      v-model="form.start_time"
                      valueFormat="YYYY-MM-DD HH:mm"
                      format="YYYY-MM-DD HH:mm"
                      :disabled-date="disabledStartDate"
                      :show-time="{ format: 'h:mm' }"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item
                    required
                    label="领劵结束时间"
                    prop="end_time"
                    :rules="{
                      required: true,
                      message: '领劵结束时间必填'
                    }"
                  >
                    <a-date-picker
                      v-model="form.end_time"
                      valueFormat="YYYY-MM-DD HH:mm"
                      format="YYYY-MM-DD HH:mm"
                      :disabled-date="disabledEndDate"
                      :show-time="{ format: 'h:mm' }"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-model-item
                    required
                    label="发放张数"
                    prop="total_num"
                    :rules="[
                      {
                        required: true,
                        message: '发放张数必填'
                      }
                    ]"
                  >
                    <a-input
                      type="number"
                      v-model="form.total_num"
                      :min="1"
                      :max="1000000"
                      suffix="张"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item
                    required
                    label="每人限领"
                    prop="limit_num_one_person"
                    :rules="[
                      {
                        required: true,
                        message: '每人限领必填，且小于发放量'
                      }
                    ]"
                  >
                    <a-input
                      type="number"
                      v-model="form.limit_num_one_person"
                      :min="1"
                      :max="1000"
                      suffix="张"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-form-model-item label="备注" prop="notes">
                <a-input
                  v-model="form.notes"
                  :max-length="50"
                  placeholder="请输入备注"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-item
                    required
                    label="券类型"
                    prop="type"
                    :rules="[
                      {
                        required: true,
                        message: '券类型'
                      }
                    ]"
                  >
                    <a-select v-model="form.type">
                      <a-select-option :value="2">商品满减劵</a-select-option>
                      <a-select-option :value="4">商品直减劵</a-select-option>
                      <a-select-option :value="1"
                        >商品条件折扣劵</a-select-option
                      >
                      <a-select-option :value="3"
                        >商品统一折扣劵</a-select-option
                      >
                      <a-select-option :value="102">店铺满减劵</a-select-option>
                      <a-select-option :value="104">店铺直减劵</a-select-option>
                      <a-select-option :value="101"
                        >店铺条件折扣劵</a-select-option
                      >
                      <a-select-option :value="103"
                        >店铺统一折扣劵</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="优惠">
                    <a-row v-if="[1, 101].includes(form.type)" :gutter="10">
                      <a-col :span="12">
                        <a-input
                          v-model="form.product_cnt"
                          :maxLength="20"
                          type="number"
                          min="2"
                          prefix="满"
                          suffix="件"
                        />
                      </a-col>
                      <a-col :span="12">
                        <a-input
                          v-model="form.discount_num"
                          :maxLength="20"
                          type="text"
                          min="1.0"
                          max="9.9"
                          prefix="打"
                          suffix="折"
                        />
                      </a-col>
                    </a-row>
                    <a-row v-if="[2, 102].includes(form.type)" :gutter="10">
                      <a-col :span="12">
                        <a-input
                          v-model="form.product_price"
                          :maxLength="20"
                          type="text"
                          min="0.01"
                          prefix="满"
                          suffix="元"
                        />
                      </a-col>
                      <a-col :span="12">
                        <a-input
                          v-model="form.discount_fee"
                          :maxLength="20"
                          type="text"
                          min="0.01"
                          prefix="减"
                          suffix="元"
                        />
                      </a-col>
                    </a-row>
                    <div v-if="[3, 103].includes(form.type)">
                      <a-input
                        v-model="form.discount_num"
                        :maxLength="20"
                        type="text"
                        min="1.0"
                        max="9.9"
                        suffix="折"
                      />
                    </div>
                    <div v-if="[4, 104].includes(form.type)">
                      <a-input
                        v-model="form.discount_fee"
                        :maxLength="20"
                        type="text"
                        min="0.01"
                        prefix="减"
                        suffix="元"
                      />
                    </div>
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-item
                    required
                    label="券有效期"
                    prop="valid_type"
                    :rules="[
                      {
                        required: true,
                        message: '券有效期必填'
                      }
                    ]"
                  >
                    <a-select v-model="form.valid_type">
                      <a-select-option :value="1">限定日期有效</a-select-option>
                      <a-select-option :value="2">领后n天有效</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="18">
                  <a-row v-if="form.valid_type === 1">
                    <a-col :span="12">
                      <a-form-model-item
                        required
                        label="有效期开始时间"
                        prop="valid_start_time"
                        :rules="{
                          required: true,
                          message: '有效期开始时间必填'
                        }"
                      >
                        <a-date-picker
                          v-model="form.valid_start_time"
                          valueFormat="YYYY-MM-DD HH:mm"
                          format="YYYY-MM-DD HH:mm"
                          :disabled-date="disabledValidStartDate"
                          :show-time="{ format: 'h:mm' }"
                        />
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-model-item
                        required
                        label="有效期结束时间"
                        prop="valid_end_time"
                        :rules="{
                          required: true,
                          message: '有效期结束时间必填'
                        }"
                      >
                        <a-date-picker
                          v-model="form.valid_end_time"
                          valueFormat="YYYY-MM-DD HH:mm"
                          format="YYYY-MM-DD HH:mm"
                          :disabled-date="disabledValidEndDate"
                          :show-time="{ format: 'h:mm' }"
                        />
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-form-model-item
                    v-if="form.valid_type === 2"
                    required
                    label="天数"
                    prop="valid_day_num"
                    :rules="[
                      {
                        required: true,
                        message: '天数不能超过180天'
                      }
                    ]"
                  >
                    <a-input
                      type="number"
                      v-model="form.valid_day_num"
                      :min="1"
                      :max="180"
                      suffix="天"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-item
                    required
                    label="推广方式"
                    prop="promote_type"
                    :rules="[
                      {
                        required: true,
                        message: '推广方式'
                      }
                    ]"
                  >
                    <a-select v-model="form.promote_type">
                      <a-select-option :value="1">店铺内推广</a-select-option>
                      <a-select-option :value="2"
                        >自定义推广渠道</a-select-option
                      >
                      <a-select-option :value="3">群定向推广</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item
                    v-if="form.promote_type === 2"
                    label="渠道名"
                    prop="customize_channel"
                  >
                    <a-input
                      v-model="form.customize_channel"
                      :max-length="10"
                      placeholder="请输入渠道名"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>

        <div v-if="form.type < 100" class="tool">
          <a-button @click="openProductDialog">
            <a-icon type="plus" /> 添加商品
          </a-button>
        </div>

        <a-table
          v-if="form.type < 100"
          class="table-wrap"
          :columns="columns"
          :data-source="form.rows"
          :pagination="false"
          rowKey="productId"
        >
          <span slot="img" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="action" slot-scope="_, record, index">
            <a-button type="link" @click="delItem(index)">
              删除
            </a-button>
          </span>
        </a-table>
        <div style="height:100px" />

        <div class="footer">
          <a-button type="primary" html-type="submit" @click="submitForm">
            保存
          </a-button>
          <a-button style="margin-left: 10px" @click="cancel">
            取消
          </a-button>
        </div>
      </a-form-model>
    </div>

    <!-- 商品选择器 -->
    <ProductDialog
      id="productDialog"
      ref="productDialog"
      v-on:selected="addItem"
      :multiple="true"
      only-self-product
    />
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const moment = require("moment");

import { mapState, mapMutations } from "vuex";

const DecoSvc = require("@/service/decoration");
const CouponSvc = require("@/service/coupon");

const columns = [
  {
    title: "图片",
    align: "center",
    dataIndex: "img",
    key: "img",
    width: 60,
    scopedSlots: { customRender: "img" }
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "价格",
    dataIndex: "price",
    key: "price"
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    width: 80,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "CouponEdit",

  data() {
    return {
      mode: "add", // 模式：add 批量新增，edit 单个编辑

      form: {
        name: "",

        type: 2, // 商品满减
        status: 1,

        promote_type: 1, // 店铺内推广
        customize_channel: "",

        start_time: null,
        end_time: null,

        total_num: null,
        limit_num_one_person: null,

        valid_type: 2, // 生效天数
        valid_day_num: null,
        valid_start_time: null,
        valid_end_time: null,

        discount_num: null,
        discount_fee: null,
        product_cnt: null,
        product_price: null,

        notes: "",

        rows: []
      }
    };
  },

  computed: {
    ...mapState({
      productList: state => state.productList
    }),

    columns() {
      return columns;
    }
  },

  mixins: [],

  methods: {
    ...mapMutations(["setProductList"]),

    openProductDialog() {
      this.$refs.productDialog.show();
    },

    addItem(vals) {
      // 去重
      for (const val of vals) {
        if (this.form.rows.findIndex(x => x.id === val.id) > -1) {
          continue;
        }

        const { id, title, img, price } = val;
        this.form.rows.push({ id, title, img, price });
      }
    },
    delItem(index) {
      this.form.rows.splice(index, 1);
    },

    // 活动日期
    disabledStartDate(startValue) {
      const endValue = this.form.end_time;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > moment(endValue).valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.start_time;
      if (!endValue || !startValue) {
        return false;
      }
      return moment(startValue).valueOf() >= endValue.valueOf();
    },

    // 有效日期
    disabledValidStartDate(startValue) {
      const endValue = this.form.valid_end_time;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > moment(endValue).valueOf();
    },
    disabledValidEndDate(endValue) {
      const startValue = this.form.valid_start_time;
      if (!endValue || !startValue) {
        return false;
      }
      return moment(startValue).valueOf() >= endValue.valueOf();
    },

    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let {
            id,

            name,
            type,

            promote_type,
            customize_channel,

            start_time,
            end_time,

            total_num,
            limit_num_one_person,

            valid_type,
            valid_day_num,
            valid_start_time,
            valid_end_time,

            discount_num,
            discount_fee,
            product_cnt,
            product_price,

            notes,

            rows
          } = this.form;

          if (type < 100 && rows.length <= 0) {
            this.$message.error("请至少添加一个商品");
            return;
          }

          const product_ids = rows.map(x => x.id);
          try {
            let val = {
              name,
              type,

              promote_type,
              customize_channel,

              start_time,
              end_time,

              total_num,
              limit_num_one_person,

              valid_type,
              valid_day_num,
              valid_start_time,
              valid_end_time,

              discount_num,
              discount_fee,
              product_cnt,
              product_price,

              notes,

              product_ids
            };

            if (this.mode === "edit") {
              val.id = id;
            }
            await CouponSvc.createOrUpdate(val);

            const msg = this.mode === "add" ? "创建成功" : "保存成功";
            this.$message.info(msg);
            this.$router.replace({ path: "/sale/coupon" });
          } catch (err) {
            console.error(err);
            this.$message.error("保存失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$router.replace({ path: "/sale/coupon" });
    }
  },

  async mounted() {
    // 商品
    try {
      const { list } = await DecoSvc.loadProductList();
      this.setProductList(list);
    } catch (err) {
      console.error(err);
      this.$message.error("加载商品出错，错误：" + err.message);
    }

    // 加载详情
    let { id } = this.$route.query || {};
    this.mode = id ? "edit" : "add";

    if (id) {
      try {
        id = parseInt(id);
        let res = await CouponSvc.detail(id);

        // 转化商品信息
        let rows = [];
        if (res.product_ids.length > 0) {
          rows = res.product_ids.map(id => {
            let p = this.productList.filter(x => x.id === id);
            p = p.length > 0 ? p[0] : { id, title: `商品加载中(${id})` };
            return p;
          });
        }
        this.form = { ...res, rows };
      } catch (err) {
        console.error(err);
        this.$message.error("加载失败，错误：" + err.message);
      }
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-coupon-edit {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;

    table {
      width: 100%;

      img {
        width: 100px;
        max-height: 100px;
      }
    }
  }

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }

  .upload-image-small {
    width: 40px;
    height: 32px;
    margin-right: 10px;
  }

  .table-wrap {
    background: #fff;
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    padding: 10px;
    text-align: center;
  }
}
</style>
